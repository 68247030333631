// OrganizationDropdown.js
import React, { useState } from 'react';

function OrganizationDropdown({ selectedTeam, teams, onSelectTeam }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div 
      className="organization-dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="organization-dropdown-trigger">
        <span>{selectedTeam ? selectedTeam.name : 'Select a team'}</span>
        <i className="fas fa-chevron-down dropdown-icon"></i>
      </div>
      {isOpen && (
        <div className="organization-dropdown-menu">
          {teams.map((team) => (
            <div 
            key={team.id} 
            className={`organization-dropdown-item ${team.id === selectedTeam?.id ? 'is-selected' : ''}`}
            onClick={() => onSelectTeam(team)}
          >
              {team.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OrganizationDropdown;
