import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import AuthComponent from './AuthComponent';
import './main.scss';
//import 'react-bulma-components/dist/react-bulma-components.min.css';

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement);
function Main() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Überprüfen, ob ein Token vorhanden ist
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Setzt isLoggedIn auf true, wenn ein Token vorhanden ist
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={!isLoggedIn ? <AuthComponent setIsLoggedIn={handleLogin} /> : <Navigate to="/" />}
        />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <App onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
