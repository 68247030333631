// TopBar.js
import React from 'react';
import { Select, MenuItem } from '@mui/material';

function TopBar({ selectedTeam, teams, onSelectTeam }) {
  return (
    <div className="is-flex is-justify-content-space-between p-4">
      <div className="is-flex is-align-items-center">
        <span className="icon-text">
          <span className="icon">
            <i className="fas fa-user-circle"></i>
          </span>
          <span>{selectedTeam ? selectedTeam.name : "Select a team"}</span>
        </span>
      </div>
    </div>
  );
}

export default TopBar;
