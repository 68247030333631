// Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import OrganizationDropdown from './OrganizationDropdown';

function Sidebar({ selectedTeam, teams, onSelectTeam, onLogout }) {
  return (
    <aside className="menu sidebar">
        
      <div className="organization-dropdown-container">
        <OrganizationDropdown
          selectedTeam={selectedTeam}
          teams={teams}
          onSelectTeam={onSelectTeam}
        />
      </div>
      <p className="menu-label">Teams</p>
      <ul className="menu-list">
        <li>
          <NavLink 
            to="/user-management" 
            className={({ isActive }) => isActive ? "is-active" : undefined}
          >
            Manage Benutzer
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/customers" 
            className={({ isActive }) => isActive ? "is-active" : undefined}
          >
            Customers
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/reports" 
            className={({ isActive }) => isActive ? "is-active" : undefined}
          >
            Reports
          </NavLink>
        </li>
      </ul>
      <p className="menu-label">SUPPORT</p>
      <ul className="menu-list">
        <li>
          <NavLink 
            to="/support" 
            className={({ isActive }) => isActive ? "is-active" : undefined}
          >
            Support
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/settings" 
            className={({ isActive }) => isActive ? "is-active" : undefined}
          >
            Settings
          </NavLink>
        </li>
      </ul>
      <button className="button is-light logout-button" onClick={onLogout}>
        Log out
      </button>
    </aside>
  );
}

export default Sidebar;
