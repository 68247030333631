// UserManagement.js
import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInstance';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

function UserManagement({ organisationId }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: null, email: '' });

  useEffect(() => {
    fetchUsers();
  }, [organisationId]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/user_management/organisations/${organisationId}/users/`);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch users.');
      setLoading(false);
    }
  };

  const handleOpenDialog = (user = { id: null, email: '' }) => {
    setCurrentUser(user);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentUser({ id: null, email: '' });
  };

  const handleSaveUser = async () => {
    try {
      if (currentUser.id) {
        // Update existing user
        await axiosInstance.put(`/user_management/users/${currentUser.id}/`, currentUser);
      } else {
        // Create new user
        await axiosInstance.post(`/user_management/users/`, currentUser);
      }
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      setError('Failed to save user.');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`/user_management/users/${userId}/`);
      fetchUsers();
    } catch (error) {
      setError('Failed to delete user.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  if (loading) return <p>Loading users...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2>User Management</h2>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Add User
      </Button>

      <TableContainer component={Paper} className="mt-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Vorname</TableCell>
              <TableCell>Nachname</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell align="right">
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => handleOpenDialog(user)}
                  >
                    Edit
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => handleDeleteUser(user.id)}
                    style={{ marginLeft: '8px' }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentUser.id ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            name="email"
            value={currentUser.email}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveUser} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserManagement;
