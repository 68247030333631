// App.js
import React, { useEffect, useState,Suspense, lazy, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import AuthComponent from './AuthComponent';
import Dashboard from './Dashboard';
import UserManagement from './UserManagement';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import Menu from './Menu';
import Neu from './Neu';

function App({ onLogout }) {
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axiosInstance.get('/user_management/users/organisations/');
                setTeams(response.data);
                if (response.data.length > 0) {
                    setSelectedTeam(response.data[0]); // Wähle das erste Team als ausgewähltes Team aus
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Organisationen:', error);
            }
        };
        fetchTeams();
    }, []);

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
    };
    
    const otherTeams = useMemo(() => {
        return teams.filter(team => team.id !== selectedTeam?.id);
    }, [teams, selectedTeam]);

    return (
        <div className="columns">
            <div className="column is-2">
            <Sidebar
              selectedTeam={selectedTeam}
              teams={teams}
              onSelectTeam={handleTeamSelect}
              onLogout={onLogout}
            />
            </div>
            <div className="column is-10">
            <TopBar     
                selectedTeam={selectedTeam} 
                teams={teams} 
                onSelectTeam={setSelectedTeam} 
            />
            <Routes>
                <Route 
                path="/" 
                element={<Dashboard selectedTeam={selectedTeam} />} 
                />

                <Route
                path="/user-management"
                element={<UserManagement organisationId={selectedTeam?.id} />}
                />
            </Routes>
            </div>
        </div>
    );
}

export default App;
