// KPICard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function KPICard({ title, value, percentage }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4">{value}</Typography>
        <Typography variant="subtitle2" style={{ color: percentage > 0 ? 'green' : 'red' }}>
          {percentage > 0 ? `▲ ${percentage}%` : `▼ ${percentage}%`}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default KPICard;
