// Dashboard.js

import React, { useEffect, useState } from 'react';
import KPICard from './KPICard';
import AnalyticsChart from './AnalyticsChart';
import TeamUserManagement from './TeamUserManagement';
import axiosInstance from './axiosInstance';

const Dashboard = ({ selectedTeam }) => {
    if (!selectedTeam) return <p>Please select a team to manage.</p>;
    
    return (
        <div className="p-4">
      <h1 className="title">Dashboard for {selectedTeam.name}</h1>
      <div className="columns is-multiline">
        <div className="column is-4">
          <KPICard title="Current MRR" value="$855.4k" percentage={16} />
        </div>
        <div className="column is-4">
          <KPICard title="Current Customers" value="127,754" percentage={17} />
        </div>
        <div className="column is-4">
          <KPICard title="Active Customers" value="73.57%" percentage={21} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <AnalyticsChart />
        </div>
        <div className="column is-6">
          <div className="box">
            <h2 className="subtitle">Recent Activity</h2>
            {/* Beispiel für eine Tabelle oder Liste der letzten Aktivitäten */}
            <ul>
              <li>Jenny Wilson - New Customer - 5 min ago</li>
              <li>Guy Hawkins - Signed up - 10 min ago</li>
              <li>Ralph Edwards - New Customer - 20 min ago</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Dashboard;
