// axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://football-tracker.de/api/',
    timeout: 5000,
  });

// Interceptor, um den Token hinzuzufügen
axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Token ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  

export default axiosInstance;
